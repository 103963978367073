// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessories-js": () => import("../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-general-js": () => import("../src/pages/faq/general.js" /* webpackChunkName: "component---src-pages-faq-general-js" */),
  "component---src-pages-faq-index-js": () => import("../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-faq-pedi-spa-chair-js": () => import("../src/pages/faq/pedi-spa-chair.js" /* webpackChunkName: "component---src-pages-faq-pedi-spa-chair-js" */),
  "component---src-pages-faq-shipping-js": () => import("../src/pages/faq/shipping.js" /* webpackChunkName: "component---src-pages-faq-shipping-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ip-js": () => import("../src/pages/ip.js" /* webpackChunkName: "component---src-pages-ip-js" */),
  "component---src-pages-pedicure-spa-chairs-index-js": () => import("../src/pages/pedicure-spa-chairs/index.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-index-js" */),
  "component---src-pages-pedicure-spa-chairs-magna-js": () => import("../src/pages/pedicure-spa-chairs/magna.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-magna-js" */),
  "component---src-pages-pedicure-spa-chairs-novo-js": () => import("../src/pages/pedicure-spa-chairs/novo.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-novo-js" */),
  "component---src-pages-pedicure-spa-chairs-tergo-js": () => import("../src/pages/pedicure-spa-chairs/tergo.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-tergo-js" */),
  "component---src-pages-pedicure-spa-chairs-vip-js": () => import("../src/pages/pedicure-spa-chairs/vip.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-vip-js" */),
  "component---src-pages-pedicure-spa-chairs-vsport-js": () => import("../src/pages/pedicure-spa-chairs/vsport.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-vsport-js" */),
  "component---src-pages-showcases-js": () => import("../src/pages/showcases.js" /* webpackChunkName: "component---src-pages-showcases-js" */),
  "component---src-pages-term-conditions-js": () => import("../src/pages/term-conditions.js" /* webpackChunkName: "component---src-pages-term-conditions-js" */),
  "component---src-pages-user-manuals-index-js": () => import("../src/pages/user-manuals/index.js" /* webpackChunkName: "component---src-pages-user-manuals-index-js" */),
  "component---src-pages-user-manuals-magna-js": () => import("../src/pages/user-manuals/magna.js" /* webpackChunkName: "component---src-pages-user-manuals-magna-js" */),
  "component---src-pages-user-manuals-novo-js": () => import("../src/pages/user-manuals/novo.js" /* webpackChunkName: "component---src-pages-user-manuals-novo-js" */),
  "component---src-pages-user-manuals-tergo-js": () => import("../src/pages/user-manuals/tergo.js" /* webpackChunkName: "component---src-pages-user-manuals-tergo-js" */),
  "component---src-pages-user-manuals-v-sport-js": () => import("../src/pages/user-manuals/v-sport.js" /* webpackChunkName: "component---src-pages-user-manuals-v-sport-js" */),
  "component---src-pages-user-manuals-vip-js": () => import("../src/pages/user-manuals/vip.js" /* webpackChunkName: "component---src-pages-user-manuals-vip-js" */),
  "component---src-pages-videos-js": () => import("../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

